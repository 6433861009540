<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem" style="margin-right: 0" :to="{path: '/book'}">
          <span style="color: #909399;font-weight: normal;">书目检索</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item class="breaditem" style="margin-left: 0">
          <span style="color: #909399">高级查询</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="selectdiv" style="margin-top: 30px">
      <div>
        <span class="mark">功能说明</span><br>
        <span class="mark">①此功能对服务器资源占用过大，建议在非高峰时段查询下载</span><br>
        <span class="mark">②下载时长根据筛选条件的不同，一般在5~10分钟，请耐心等待</span><br>
        <span class="mark">③会员暂时需管理员授权才可使用此功能</span><br>
        <span class="mark">④下载格式为.csv文件</span><br>
      </div>
    </div>
    <div class="selectdiv" style="margin-top: 20px">
      <div>
        <span class="mark">查询逻辑</span><br>
        <span class="mark">①系统筛选出指定供货商的全部书目信息</span><br>
        <span class="mark">②合并同一本书相同折扣的供货商库存本数</span><br>
        <span class="mark">③对合并后的结果进行折扣范围和库存范围的筛选</span><br>
        <span class="mark">④依据所选模式筛选一本书的最低折扣或最多库存</span>
      </div>
    </div>
    <div class="selectdiv" style="margin-top: 50px">
      <el-radio-group v-model="query.seller" size="small" :disabled="loading">
        <el-radio-button label=0>全部供货商</el-radio-button>
        <el-radio-button label=1>关注的供货商</el-radio-button>
      </el-radio-group>
      <el-input-number class="family" size="small" v-model="query.minDiscount" style="margin-left: 30px"
                       :controls="false" :precision="2" :min="0" :max="10"
                       placeholder="折扣下限" :disabled="loading"/>
      <span class="el-icon-minus split"/>
      <el-input-number class="family" size="small" v-model="query.maxDiscount"
                       :controls="false" :precision="2" :min="0" :max="10"
                       placeholder="折扣上限" :disabled="loading"/>
      <el-input-number class="family" size="small" v-model="query.minNum" style="margin-left: 30px"
                       :controls="false" :min="0" :max="99999"
                       placeholder="库存下限" :disabled="loading"/>
      <span class="el-icon-minus split"/>
      <el-input-number class="family" size="small" v-model="query.maxNum"
                       :controls="false" :min="0" :max="99999"
                       placeholder="库存上限" :disabled="loading"/>
      <el-radio-group v-model="query.mode" size="small" :disabled="loading" style="margin-left: 30px">
        <el-radio-button label=0>折扣最低模式</el-radio-button>
        <el-radio-button label=1>库存最多模式</el-radio-button>
      </el-radio-group>
    </div>
    <div class="selectdiv" style="margin-top: 40px">
      <el-input-number class="family" size="small" v-model="query.size"
                       :controls="false" :min="1" :max="100"
                       placeholder="100" :disabled="loading"/>
      <span class="title" style="">万条结果 / csv</span>
      <el-button type="primary" icon="el-icon-download" class="button"
                 size="small" @click="getAdvance" :disabled="loading"
                 :loading="loading">{{loading?'正在下载':'查询下载'}}
      </el-button>
      <span v-if="loading" style="color: #EB6100;margin-left: 30px">下载中， 请勿刷新、关闭、跳转、重复打开该页面！</span>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        query: {
          seller: 0,
          mode: 0,
          minDiscount: undefined,
          maxDiscount: undefined,
          minNum: undefined,
          maxNum: undefined,
          size: 100
        },
        loading: false
      }
    },
    methods: {
      async getAdvance () {
        if (this.query.minDiscount !== undefined && this.query.maxDiscount !== undefined) {
          if (this.query.minDiscount > this.query.maxDiscount) {
            return this.$msg.warning('折扣下限大于折扣上限！')
          }
        }
        if (this.query.minNum !== undefined && this.query.maxNum !== undefined) {
          if (this.query.minNum > this.query.maxNum) {
            return this.$msg.warning('库存下限大于折扣上限！')
          }
        }

        try {
          this.loading = true
          const res = await this.$http.get('downloadAdvance',
            {
              responseType: 'blob',
              params:
                {
                  seller: this.query.seller,
                  mode: this.query.mode,
                  minDiscount: this.query.minDiscount === undefined ? -1 : this.query.minDiscount,
                  maxDiscount: this.query.maxDiscount === undefined ? -1 : this.query.maxDiscount,
                  minNum: this.query.minNum === undefined ? -1 : this.query.minNum,
                  maxNum: this.query.maxNum === undefined ? -1 : this.query.maxNum,
                  size: this.query.size === undefined ? 100 : this.query.size
                }
            })
          this.loading = false

          if (res.headers['content-type'] !== ('application/zip')) {
            const reader = new FileReader()
            reader.readAsText(res.data)
            reader.onload = e => {
              const data = JSON.parse(e.target.result)
              if (!this.isLogin(data)) {
                return
              }
              this.$msg.warning(data.msg)
            }
            return
          }

          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.download = '检索结果.zip'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
          this.$msg.success('下载成功！')
        } catch (e) {
          this.loading = false
          this.$msg.error(this.netError)
        }
      }
    }
  }
</script>

<style scoped>

  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
    width: 90px;
    height: 32px;
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
    color: #606266;
  }

  .split {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 32px;
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
    color: #606266;
  }

  .mark {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
    color: #909399;
  }

  /deep/ .el-radio-button__inner {
    width: 105px;
    padding: 9px 10px;
    font-size: 13px;
  }

  .el-input-number {
    width: 90px !important;
  }

  /deep/ .button {
    width: 210px !important;
    height: 32px !important;
    font-size: 13px;
    padding: 0;
    margin-left: 30px;
  }

</style>
